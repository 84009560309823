<template>
  <Navbar />
  <main id="main" class="container pt-4">
    <div class="row row-cols-1 g-3">
      <div
        v-if="activeApp('inspection') || activeApp('inspection_plan')"
        class="col"
      >
        <router-link
          :to="{
            name: 'Inspection',
          }"
          class="btn btn-lg btn-secondary w-100 py-3"
          ><i class="bi bi-clipboard-check d-block" style="font-size: 2rem"></i
          >検査</router-link
        >
      </div>
      <div class="col">
        <a
          v-on:click.prevent="confirmLogout()"
          class="btn btn-lg btn-outline-danger w-100 py-3"
          href="#"
          ><i class="bi bi-x-circle-fill d-block" style="font-size: 2rem"></i
          >作業終了</a
        >
      </div>
    </div>
  </main>

  <Confirm ref="confirm" @confirm-true="confirmTrue" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import Confirm from '@/components/TheConfirm.vue'

export default {
  name: 'AppHome',
  components: {
    Navbar,
    Confirm,
  },

  computed: {
    ...mapGetters({
      activeApp: 'auth/activeApp',
    }),
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    confirmLogout() {
      this.$refs.confirm.showConfirm('', '作業を終了しますか？')
    },
    async confirmTrue() {
      await this.logout()
      this.$router.push({
        name: 'AppLogin',
      })
    },
  },
}
</script>
