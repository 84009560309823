<template>
  <div class="ratio ratio-16x9 mb-3">
    <video id="videoInput" controls playsinline muted autoplay></video>
    <canvas id="videoCanvas" width="1280" height="720" class="bg-dark"></canvas>
  </div>
  <div class="row">
    <div class="col">
      <button
        v-if="isStream"
        class="btn btn-lg btn-primary w-100 py-4"
        v-on:click.prevent="captureVideo()"
      >
        撮影
      </button>
      <button
        v-else
        class="btn btn-lg btn-secondary w-100 py-4"
        v-on:click.prevent="getStream()"
      >
        カメラ起動
      </button>
    </div>
    <div class="col">
      <button
        class="btn btn-lg btn-danger w-100 py-4"
        v-on:click.prevent="reset()"
        :disabled="!isCapture"
      >
        クリア
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Camera',

  emits: ['captureFunc'],

  data() {
    return {
      isStream: false,
      isCapture: false,
      video: null,
      videoCanvas: null,
      videoContext: null,
      canvas: null,
      context: null,
    }
  },

  mounted() {
    this.reset()
    this.videoCanvas = document.getElementById('videoCanvas')
    this.videoContext = this.videoCanvas.getContext('2d')
  },
  unmounted() {
    this.reset()
  },

  methods: {
    reset() {
      this.isStream = false
      this.isCapture = false
      if (this.videoContext != null) {
        this.videoContext.clearRect(
          0,
          0,
          this.videoCanvas.width,
          this.videoCanvas.height
        )
      }
      this.clearVideo()
    },
    getStream() {
      this.video = document.getElementById('videoInput')
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            aspectRatio: 1.777777,
            facingMode: 'environment',
          },
        })
        .then((stream) => {
          this.video.srcObject = stream
          this.video.addEventListener('timeupdate', this.drawVideo, false)
          this.isStream = true
        })
    },
    drawVideo() {
      const currentSize = this.getCurrentSize(this.videoCanvas)
      this.videoContext.drawImage(
        this.video,
        0,
        0,
        this.video.videoWidth,
        this.video.videoHeight,
        currentSize.x,
        currentSize.y,
        currentSize.w,
        currentSize.h
      )
    },
    clearVideo() {
      if (this.video != null) {
        this.video.srcObject.getVideoTracks().forEach((track) => {
          track.stop()
          this.video.srcObject.removeTrack(track)
        })
      }
      this.isStream = false
    },
    captureVideo() {
      this.isCapture = true
      this.videoContext.drawImage(this.videoCanvas, 0, 0)
      this.$emit('captureFunc', this.videoCanvas.toDataURL('image/webp'))
      this.clearVideo()
    },
    getCurrentSize(targetCanvas) {
      let currentW = targetCanvas.width,
        currentH = targetCanvas.height,
        currentX = 0,
        currentY = 0

      if (this.video.videoWidth < this.video.videoHeight) {
        // 縦向き・幅に合わせる
        const videoR = targetCanvas.width / this.video.videoWidth
        currentW = targetCanvas.width
        currentH = this.video.videoHeight * videoR
      }
      if (this.video.videoWidth > this.video.videoHeight) {
        // 横向き
        const orgR = 9 / 16
        const videoR = this.video.videoHeight / this.video.videoWidth
        if (orgR < videoR) {
          // 比率が大きい場合は高さに合わせる
          const differenceW = targetCanvas.width - targetCanvas.width * videoR
          currentW = targetCanvas.width * videoR
          currentH = targetCanvas.height
          currentX = differenceW / 2
        }
        if (orgR > videoR) {
          // 比率が小さい場合は幅に合わせる
          const differenceH = targetCanvas.height - targetCanvas.height * videoR
          currentW = targetCanvas.width
          currentH = targetCanvas.height * videoR
          currentY = differenceH / 2
        }
      }

      return {
        w: currentW,
        h: currentH,
        x: currentX,
        y: currentY,
      }
    },
  },
}
</script>

<style scoped>
.video-wrap {
  height: calc(100vw * 0.66);
  position: relative;
  width: 100%;
  z-index: 0;
}

.video-camera {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
</style>
