<template>
  <Navbar>
    <template v-slot:navbarButton>
      <router-link
        :to="{
          name: 'InspectionResume',
        }"
        class="btn btn-danger ms-auto me-sm-3 me-2"
        >中断</router-link
      >
      <span class="text-white d-block">{{
        inspection_result.inspection_result_number
      }}</span>
    </template>
  </Navbar>

  <main id="main" class="bg-light">
    <div class="container pt-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ inspection_item.title }}</h5>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ inspection_result.inspection_name }}
          </h6>
          <hr />
          <p>{{ inspection_item.description }}</p>
          <div
            v-if="inspection_item.attention"
            class="border border-danger text-danger text-center p-1 mb-2"
          >
            {{ inspection_item.attention }}
          </div>
          <div class="row gy-4">
            <div class="col-lg-6">
              <div class="ratio ratio-16x9">
                <img
                  v-if="inspection_item.image"
                  :src="inspection_item.image"
                  class="img-fluid border"
                />
                <img
                  v-else
                  src="@/assets/no_image.svg"
                  class="img-fluid bg-secondary"
                  alt="..."
                />
              </div>
            </div>
            <div class="col-lg-6">
              <InspectionCamera
                v-if="inspection_item.type == 'camera'"
                @result-func="formSubmit"
              />
              <InspectionConfirm
                v-if="inspection_item.type == 'confirm'"
                @result-func="formSubmit"
              />
              <InspectionInput
                v-if="inspection_item.type == 'input'"
                @result-func="formSubmit"
              />
              <InspectionSelect
                v-if="inspection_item.type == 'select'"
                :inspection_item="inspection_item"
                @result-func="formSubmit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/inspection/InspectionNavbar.vue'
import InspectionCamera from '@/components/inspection/InspectionCamera.vue'
import InspectionConfirm from '@/components/inspection/InspectionConfirm.vue'
import InspectionInput from '@/components/inspection/InspectionInput.vue'
import InspectionSelect from '@/components/inspection/InspectionSelect.vue'

export default {
  name: 'InspectionResult',
  components: {
    Navbar,
    InspectionCamera,
    InspectionConfirm,
    InspectionInput,
    InspectionSelect,
  },

  data() {
    return {
      form_date: {},
    }
  },

  computed: {
    ...mapGetters({
      inspection_result: 'inspection_result/detail',
      inspection_item: 'inspection_item/detail',
      timeout: 'alert/timeout',
    }),
  },

  async created() {
    await this.init()
  },

  methods: {
    ...mapActions({
      searchItem: 'inspection_item/searchData',
      postData: 'inspection_item/postData',
    }),
    async init() {
      await this.searchItem().then((response) => {
        if (response.status == 204) {
          this.$store.dispatch('alert/setSuccessMessage', {
            message: '検査が終了しました。',
          })
          window.setTimeout(() => {
            this.$router.push({ name: 'InspectionEnd' })
          }, this.timeout + 500)
        }
      })
      this.form_date = {
        inspection_result_id: this.inspection_result.id,
        type: this.inspection_item.type_name,
        title: this.inspection_item.title,
      }
    },
    async formSubmit(form_data) {
      this.form_date = { ...this.form_date, ...form_data }
      await this.postData(this.form_date).then(() => {
        if (this.form_date.is_success == true) {
          this.init()
        } else {
          this.$store.dispatch('alert/setInfoMessage', {
            message: '検査を中断します。次の画面で現状を保存してください。',
          })
          window.setTimeout(() => {
            this.$router.push({ name: 'InspectionResume' })
          }, this.timeout + 500)
        }
      })
    },
  },
}
</script>
