<template>
  <slot></slot>
  <input
    type="file"
    class="d-none"
    :id="button_id"
    :accept="accept"
    @change="onFileChange"
  />
</template>

<script>
import { isImage, isVideo } from '@/utils/fileTypeUtils.js'
import { randomString } from '@/utils/stringUtils.js'

export default {
  name: 'AppFileSelect',

  emits: ['selectedFunc'],
  props: {
    accept: {
      type: String,
      required: false,
      default: 'image/jpeg,image/png',
    },
    max_file_size: {
      type: Number,
      required: false,
      default: 20971520,
    },
  },

  computed: {
    button_id: function () {
      return 'file-' + this.randomString()
    },
  },

  methods: {
    isImage,
    isVideo,
    randomString,
    fileEvent() {
      document.getElementById(this.button_id).click()
    },
    async onFileChange(e) {
      e.preventDefault()

      const files = e.target.files || e.dataTransfer.files
      let file = files[0]
      if (!this.fileCheck(file)) {
        return
      }

      if (this.isImage({ file_type: file.type })) {
        this.getImageDataURL(file, 1200, 900)
          .then((dataURL) => {
            this.$emit('selectedFunc', dataURL, 'image/webp')
          })
          .catch(() => {
            console.log('ファイルが取得できませんでした。')
          })
      }
      if (this.isVideo({ file_type: file.type })) {
        this.getVideoDataURL(file)
          .then((dataURL) => {
            this.$emit('selectedFunc', dataURL, file.type)
          })
          .catch(() => console.log('ファイルが取得できませんでした。'))
      }
      return
    },
    getVideoDataURL(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    getImageDataURL(file, maxWidth, maxHeight) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = function () {
          const img = new Image()
          img.onload = function () {
            const canvas = document.createElement('canvas')
            let width = img.width
            let height = img.height

            // 最大幅と最大高さを考慮してリサイズ
            if (width > maxWidth) {
              height *= maxWidth / width
              width = maxWidth
            }
            if (height > maxHeight) {
              width *= maxHeight / height
              height = maxHeight
            }

            // 画像を描画してリサイズ
            canvas.width = width
            canvas.height = height
            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)

            // データURLを生成
            const dataURL = canvas.toDataURL('image/webp')
            resolve(dataURL)
          }
          img.onerror = reject
          img.src = reader.result
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },

    fileCheck(file) {
      if (!new RegExp(file?.type).test(this.accept)) {
        this.$store.dispatch('alert/setErrorMessage', {
          message:
            file.type +
            ' は許可されていないファイル種別です。\n登録できるファイル種別: ' +
            this.accept,
        })
        return false
      }

      if (file.size > this.max_file_size) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: 'ファイル容量が上限(20MB)を超えています。',
        })
        return false
      }

      return true
    },
  },
}
</script>
