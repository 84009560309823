import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: [],
  query_params: {
    inspection_result_number: '',
  },
  detail: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  results: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
  query_params: (state) => state.query_params,
  detail: (state) => state.detail,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = []),
  setQueryParams: (state, query_params) => (state.query_params = query_params),
  clearQueryParams: (state) => (state.query_params = {}),
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearQueryParams')
      commit('clearDetail')
      resolve()
    })
  },
  async fetchList({ commit, getters }) {
    const response = await api.get('frontend/inspection_result_temps/', {
      params: getters.query_params,
    })
    commit('setData', response.data)
  },
  setQuery({ commit }, query_params) {
    Object.keys(query_params).map((key) => {
      if (typeof query_params[key] == 'string') {
        query_params[key] = query_params[key].trim()
      }
    })
    commit('setQueryParams', query_params)
  },
  async fetchData({ commit }, id) {
    const response = await api.get(
      'frontend/inspection_result_temps/' + id + '/'
    )
    commit('setDetail', response.data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
