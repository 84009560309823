<template>
  <Navbar />

  <main id="main" class="bg-light">
    <div class="container pt-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">検査終了</h5>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ inspection_result.inspection_name }}
          </h6>
          <hr />
          <p>検査が終わりました。</p>
          <div class="mb-4">
            <label class="text-muted mb-1" for="worker">検査員</label>
            <input
              v-model="worker"
              type="text"
              class="form-control form-control-lg"
              id="worker"
              readonly
            />
          </div>
          <div class="mb-4">
            <label class="text-muted mb-1" for="ended_at">終了日時</label>
            <input
              v-model="end_datetime"
              type="datetime-local"
              class="form-control form-control-lg"
              id="ended_at"
              readonly
            />
          </div>
        </div>
      </div>
    </div>

    <div class="container pt-5">
      <div class="row row-cols-1 g-3">
        <div class="col">
          <button
            class="btn btn-lg btn-dark w-100 py-4"
            v-on:click.prevent="formSubmit()"
          >
            確認しました
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { inject } from 'vue'
import Navbar from '@/components/inspection/InspectionNavbar.vue'

export default {
  name: 'InspectionEnd',
  components: {
    Navbar,
  },

  data() {
    return {
      end_datetime: '',
    }
  },

  computed: {
    ...mapGetters({
      userData: 'auth/userData',
      inspection_result: 'inspection_result/detail',
      has_remaining: 'inspection_plan/has_remaining',
    }),
    worker: function () {
      return this.userData('display_name')
    },
  },

  mounted() {
    const dayjs = inject('dayjs')
    this.end_datetime = dayjs().format('YYYY-MM-DDTHH:mm')
  },

  methods: {
    ...mapActions({
      updateResult: 'inspection_result/patchData',
      updateResultStore: 'inspection_result/updateStore',
      addResultCount: 'inspection_plan/addResultCount',
      generateNumber: 'inspection/postGenerateNumber',
    }),
    async formSubmit() {
      await this.updateResult({
        id: this.inspection_result.id,
        end_datetime: this.end_datetime,
      })

      await this.addResultCount()
      if (this.has_remaining) {
        try {
          await this.generateNumber().then((response) => {
            this.updateResultStore(response)
            this.$router.push({
              name: 'InspectionPlanStart',
            })
          })
        } catch (error) {
          this.$store.dispatch('alert/setErrorMessage', {
            message: '検査番号の生成に失敗しました。',
          })
        }
      } else {
        this.$store.dispatch('alert/setInfoMessage', {
          message: 'この検査は製作指示数に達しました。',
        })
        window.setTimeout(() => {
          this.$store.dispatch('clearDataStores')
          this.$router.push({
            name: 'InspectionPlan',
          })
        }, this.$store.getters['alert/timeout'])
      }
    },
  },
}
</script>
