<template>
  <div class="row row-cols-1 gy-3">
    <div class="col">
      <textarea
        v-model="description"
        class="form-control"
        placeholder="入力してください。"
        id="description"
        rows="5"
      ></textarea>
      <button
        class="btn btn-lg btn-success w-100 py-3 mt-1"
        v-on:click.prevent="success()"
      >
        <i class="bi bi-check-circle-fill me-2"></i> 入力しました
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InspectionInput',

  emits: ['resultFunc'],

  data() {
    return {
      description: null,
    }
  },

  methods: {
    success() {
      if (!this.description) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: '入力してください。',
        })
      } else {
        this.$emit('resultFunc', {
          is_success: true,
          description: this.description,
          photo: null,
        })
      }
    },
  },
}
</script>
