<template>
  <div id="appWrap">
    <Alert />
    <router-view></router-view>
    <Loading />
  </div>
</template>

<script>
import Alert from '@/components/TheAlert.vue'
import Loading from '@/components/TheLoading.vue'

export default {
  name: 'App',
  components: {
    Alert,
    Loading,
  },
}
</script>

<style>
#main {
  min-height: calc(100vh - 56px);
}

.table > tbody > tr > th,
.table > thead > tr > th {
  text-align: center;
}
.table-row > tbody > tr > th,
.table-row > thead > tr > th {
  text-align: left;
}
.table-responsive > .table > tbody > tr > td,
.table-responsive > .table > tbody > tr > th,
.table-responsive > .table > thead > tr > td,
.table-responsive > .table > thead > tr > th {
  white-space: nowrap;
}
</style>
