import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  detail: {},
  query_params: {
    inspection_plan_number: '',
  },
  inspection_plan_number: '',
  result_count: 0,
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  query_params: (state) => state.query_params,
  detail: (state) => state.detail,
  inspection_plan_number: (state) => state.inspection_plan_number,
  has_remaining(state) {
    // 製作指示数と比較する
    return state.result_count < state.detail.number_unit
  },
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setQueryParams: (state, query_params) => (state.query_params = query_params),
  clearQueryParams: (state) => (state.query_params = {}),
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
  setPlanNumber: (state, inspection_plan_number) =>
    (state.inspection_plan_number = inspection_plan_number),
  clearPlanNumber: (state) => (state.inspection_plan_number = ''),
  setResultCount: (state, result_count) => (state.result_count = result_count),
  clearResultCount: (state) => (state.result_count = 0),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearQueryParams')
      commit('clearDetail')
      commit('clearPlanNumber')
      commit('clearResultCount')
      resolve()
    })
  },
  async fetchData({ commit, getters }) {
    const response = await api.get('frontend/inspection_plans/', {
      params: getters.query_params,
    })
    commit('setDetail', response.data)
    commit('setPlanNumber', response.data.inspection_plan_number)
  },
  setQuery({ commit }, query_params) {
    Object.keys(query_params).map((key) => {
      if (typeof query_params[key] == 'string') {
        query_params[key] = query_params[key].trim()
      }
    })
    commit('setQueryParams', query_params)
  },
  async postResultData({ state, rootState }) {
    let post_data = {
      inspection_plan_id: state.detail.id,
      inspection_result_id: rootState.inspection_result.detail.id,
    }
    await api.post('frontend/inspection_plan_results/', post_data)
  },
  async fetchResultList({ commit, getters }) {
    const response = await api.get('frontend/inspection_plan_results/', {
      params: getters.query_params,
    })
    commit('inspection_temp/setData', response.data, { root: true })
    // 検索条件が完全一致じゃない場合は修正が必要
    commit('setPlanNumber', getters.query_params.inspection_plan_number)
  },
  async addResultCount({ state, commit, rootState }) {
    const response = await api.get('frontend/inspection_plan_results/count/', {
      params: {
        'inspection_plan_number': state.inspection_plan_number,
        'inspection_type_name': rootState.inspection.detail.inspection_type_name
      },
    })
    // 検査済み個数を更新
    commit('setResultCount', response.data.result_count)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
