import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  detail: {},
  item_position: 0,
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  detail: (state) => state.detail,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
  setItemPosition: (state, item_position) =>
    (state.item_position = item_position),
  clearItemPosition: (state) => (state.item_position = 0),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearDetail')
      commit('clearItemPosition')
      resolve()
    })
  },
  async postData({ commit }, form_data) {
    const response = await api.post('frontend/inspection_results/', form_data)
    commit('setDetail', response.data)
  },
  async patchData({ commit }, form_data) {
    const response = await api.patch(
      'frontend/inspection_results/' + form_data.id + '/',
      form_data
    )
    commit('setDetail', response.data)
  },
  async postTempData({ state, rootState }) {
    let post_data = {
      inspection_id: rootState.inspection.detail.inspection_id,
      inspection_result_id: state.detail.id,
      inspection_result_number: state.detail.inspection_result_number,
    }
    await api.post('frontend/inspection_result_temps/', post_data)
  },
  updateStore({ commit, rootState }, data) {
    commit('clearItemPosition')
    commit('setDetail', {
      inspection_type_name: rootState.inspection.detail.inspection_type_name,
      inspection_name: rootState.inspection.detail.inspection_name,
      inspection_result_number_id: data.id,
      inspection_result_number: data.inspection_result_number,
    })
  },
  updateItemPosition({ state, commit }) {
    commit('setItemPosition', state.item_position + 1)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
