import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  detail: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  detail: (state) => state.detail,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearDetail')
      resolve()
    })
  },
  async postData({ dispatch }, formData) {
    // 必要なデータのみにする
    const requiredKeys = [
      'inspection_result_id',
      'type',
      'title',
      'is_success',
      'description',
      'photo',
    ]
    const submitData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) =>
        requiredKeys.includes(key)
      )
    )

    try {
      await api.post('frontend/inspection_result_details/', submitData)
      dispatch('inspection_result/updateItemPosition', null, {
        root: true,
      })
    } catch (error) {
      throw error
    }
  },
  async searchData({ commit, rootState }) {
    let post_data = {
      inspection_id: rootState.inspection.detail.inspection_id,
      position: rootState.inspection_result.item_position,
    }
    const response = await api.post('frontend/inspection_items/', post_data)
    commit('setDetail', response.data)
    return response
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
