<template>
  <div class="animate__animated animate__fadeIn">
    <div
      class="row vh-100 bg-dark g-0 d-flex align-content-around align-content-lg-center camera-container"
    >
      <div class="col-lg-10">
        <ImageCamera
          ref="imageCamera"
          @after-capture-frame="afterCaptureImageCamera"
        />
      </div>
      <div
        class="col-lg-2 d-lg-flex align-content-lg-center flex-lg-wrap mt-5 mt-lg-0"
      >
        <div class="row g-0">
          <div
            class="col-6 col-lg-12 d-flex justify-content-center justify-content-lg-end"
          >
            <button
              type="button"
              class="btn btn-link btn-lg link-light"
              @click.prevent="stopImageCamera()"
            >
              <i class="bi bi-x-circle-fill fs-1"></i>
            </button>
          </div>
          <div
            class="col-6 col-lg-12 d-flex justify-content-center justify-content-lg-end"
          >
            <button
              type="button"
              class="btn btn-link btn-lg link-light"
              @click.prevent="captureImageCamera()"
            >
              <i class="bi bi-record-circle-fill fs-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCamera from '@/components/AppImageCamera.vue'

export default {
  name: 'InspectionImageCamera',
  components: {
    ImageCamera,
  },

  emits: ['hiddenCamera', 'captureCamera'],

  mounted() {
    this.$refs.imageCamera.startStream()
  },
  unmounted() {},

  methods: {
    stopImageCamera() {
      this.$emit('hiddenCamera')
    },
    captureImageCamera() {
      this.$refs.imageCamera.captureFrame()
    },
    afterCaptureImageCamera(dataURL, fileType) {
      this.$emit('captureCamera', dataURL, fileType)
    },
  },
}
</script>

<style scoped>
.camera-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
</style>
