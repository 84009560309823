import InspectionPlanHome from '@/pages/inspection_plan/Home.vue'
import InspectionPlan from '@/pages/inspection_plan/InspectionPlan.vue'
import InspectionPlanDetail from '@/pages/inspection_plan/InspectionPlanDetail.vue'
import InspectionPlanCall from '@/pages/inspection_plan/InspectionCall.vue'
import InspectionPlanStart from '@/pages/inspection_plan/InspectionStart.vue'
import InspectionPlanResult from '@/pages/inspection_plan/InspectionResult.vue'
import InspectionPlanResume from '@/pages/inspection_plan/InspectionResume.vue'
import InspectionPlanEnd from '@/pages/inspection_plan/InspectionEnd.vue'
import InspectionPlanRestart from '@/pages/inspection_plan/InspectionRestart.vue'
import InspectionPlanTemp from '@/pages/inspection_plan/InspectionTemp.vue'
import InspectionPlanTempDetail from '@/pages/inspection_plan/InspectionTempDetail.vue'

export default {
  routes: [
    {
      path: '/inspection_plan',
      component: InspectionPlanHome,
      name: 'InspectionPlanHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'plans',
          component: InspectionPlan,
          name: 'InspectionPlan',
        },
        {
          path: 'detail',
          component: InspectionPlanDetail,
          name: 'InspectionPlanDetail',
        },
        {
          path: 'calls',
          component: InspectionPlanCall,
          name: 'InspectionPlanCall',
        },
        {
          path: 'start',
          component: InspectionPlanStart,
          name: 'InspectionPlanStart',
        },
        {
          path: 'result',
          component: InspectionPlanResult,
          name: 'InspectionPlanResult',
        },
        {
          path: 'resume',
          component: InspectionPlanResume,
          name: 'InspectionPlanResume',
        },
        {
          path: 'end',
          component: InspectionPlanEnd,
          name: 'InspectionPlanEnd',
        },
        {
          path: 'restart',
          component: InspectionPlanRestart,
          name: 'InspectionPlanRestart',
        },
        {
          path: 'temps',
          component: InspectionPlanTemp,
          name: 'InspectionPlanTemp',
        },
        {
          path: 'temps/:id([0-9]+)',
          component: InspectionPlanTempDetail,
          name: 'InspectionPlanTempDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
      ],
    },
  ],
}
