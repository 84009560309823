import { createRouter, createWebHistory } from 'vue-router'

import AppHome from '@/pages/AppHome.vue'
import AppLogin from '@/pages/AppLogin.vue'
import NotFound from '@/pages/NotFound.vue'

import Inspection from './inspection'
import InspectionPlan from './inspection_plan'

// store
import store from '@/store'

const routes = [
  {
    path: '/',
    component: AppHome,
    name: 'AppHome',
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    component: AppLogin,
    name: 'AppLogin',
  },
  ...Inspection.routes,
  ...InspectionPlan.routes,
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'NotFound',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.getters['auth/is_logged_in']) {
    if (store.getters['auth/is_token']) {
      store.dispatch('auth/renew').then(() => {
        next()
      })
    } else {
      router.replace({
        name: 'AppLogin',
        query: { redirect: to.fullPath },
      })
    }
  } else {
    next()
  }
})

export default router
