<template>
  <Navbar>
    <template v-slot:navbarButton>
      <router-link
        :to="{
          name: 'InspectionPlan',
        }"
        class="btn btn-dark"
        >戻る</router-link
      >
    </template>
  </Navbar>

  <main id="main" class="bg-light">
    <div class="container pt-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">指示書</h5>
          <h6 class="card-subtitle mb-2 text-muted"></h6>
          <hr />
          <div v-if="inspection_plan">
            <table class="table table-bordered mb-3">
              <tbody>
                <tr>
                  <th class="col-sm-4 bg-light">得意先名</th>
                  <td class="col-sm-8">
                    {{ inspection_plan.client_name }}
                  </td>
                </tr>
                <tr>
                  <th class="bg-light">受注番号</th>
                  <td>{{ inspection_plan.order_number }}</td>
                </tr>
                <tr>
                  <th class="bg-light">指示番号</th>
                  <td>{{ inspection_plan.inspection_plan_number }}</td>
                </tr>
                <tr>
                  <th class="bg-light">納期</th>
                  <td>{{ inspection_plan.order_deadline }}</td>
                </tr>
                <tr>
                  <th class="bg-light">型式</th>
                  <td>{{ inspection_plan.product_model }}</td>
                </tr>
                <tr>
                  <th class="bg-light">機種コード</th>
                  <td>{{ inspection_plan.product_code }}</td>
                </tr>
                <tr>
                  <th class="bg-light">製作指示数</th>
                  <td>{{ inspection_plan.number_unit }}</td>
                </tr>
                <tr>
                  <th class="bg-light">製作指示残数</th>
                  <td>{{ inspection_plan.number_remaining }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="inspection_plan" class="container pt-5">
      <div class="row row-cols-1 g-3">
        <div class="col">
          <button
            class="btn btn-lg btn-dark w-100 py-4"
            v-on:click.prevent="next()"
          >
            検査開始
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/inspection/InspectionNavbar.vue'

export default {
  name: 'InspectionPlanDetail',
  components: {
    Navbar,
  },

  data() {
    return {
      query: {
        code: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      inspection_plan: 'inspection_plan/detail',
    }),
  },

  async created() {
    await this.getData(this.id)
      .then(() => {
        this.query.code = this.inspection_plan.product_model
      })
      .catch((error) => {
        if (error.status == 404) {
          window.setTimeout(() => {
            this.$router.push({
              name: 'InspectionPlan',
            })
          }, this.$store.getters['alert/timeout'])
        }
      })
  },

  methods: {
    ...mapActions({
      getData: 'inspection_plan/fetchData',
      setQuery: 'inspection_call/setQuery',
    }),
    next() {
      this.setQuery(this.query).then(() => {
        this.$router.push({
          name: 'InspectionPlanCall',
        })
      })
    },
  },
}
</script>
