import api from '@/api'
import dayjs from 'dayjs'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  detail: {},
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  detail: (state) => state.detail,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearDetail')
      resolve()
    })
  },
  async postGenerateNumber({ state, rootGetters }) {
    let post_data = {
      inspection_type_id: state.detail.inspection_type_id,
      inspection_id: state.detail.inspection_id,
      prefix_code: rootGetters['inspection_call/code'],
      prefix_date: dayjs().format('YYYYMMDD'),
      prefix_plan: rootGetters['inspection_plan/inspection_plan_number'],
    }
    const response = await api.post(
      'frontend/inspection_result_numbers/',
      post_data
    )
    return response.data
  },
  async deleteInspectionNumber({ rootState }) {
    let id = rootState.inspection_result.detail.inspection_result_number_id
    if (id) {
      const response = await api.delete(
        'frontend/inspection_result_numbers/' + id + '/'
      )
      return response.data
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
