<template>
  <div class="row row-cols-1 gy-3">
    <div class="col">
      <button
        class="btn btn-lg btn-success w-100 py-4"
        v-on:click.prevent="success()"
      >
        <i class="bi bi-check-circle-fill me-2"></i> 確認しました
      </button>
    </div>
    <div class="col">
      <button
        class="btn btn-lg btn-danger w-100 py-4"
        v-on:click.prevent="failure()"
      >
        <i class="bi bi-x-circle-fill me-2"></i> NGでした
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InspectionConfirm',

  emits: ['resultFunc'],

  methods: {
    success() {
      this.$emit('resultFunc', {
        is_success: true,
        description: null,
        photo: null,
      })
    },
    failure() {
      this.$emit('resultFunc', {
        is_success: false,
        description: null,
        photo: null,
      })
    },
  },
}
</script>
