import InspectionHome from '@/pages/inspection/Home.vue'
import Inspection from '@/pages/inspection/Inspection.vue'
import InspectionCode from '@/pages/inspection/InspectionCode.vue'
import InspectionCall from '@/pages/inspection/InspectionCall.vue'
import InspectionStart from '@/pages/inspection/InspectionStart.vue'
import InspectionRestart from '@/pages/inspection/InspectionRestart.vue'
import InspectionResult from '@/pages/inspection/InspectionResult.vue'
import InspectionResume from '@/pages/inspection/InspectionResume.vue'
import InspectionEnd from '@/pages/inspection/InspectionEnd.vue'
import InspectionTemp from '@/pages/inspection/InspectionTemp.vue'
import InspectionTempDetail from '@/pages/inspection/InspectionTempDetail.vue'

export default {
  routes: [
    {
      path: '/inspection',
      component: InspectionHome,
      name: 'InspectionHome',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'inspections',
          component: Inspection,
          name: 'Inspection',
        },
        {
          path: 'code',
          component: InspectionCode,
          name: 'InspectionCode',
        },
        {
          path: 'calls',
          component: InspectionCall,
          name: 'InspectionCall',
        },
        {
          path: 'start',
          component: InspectionStart,
          name: 'InspectionStart',
        },
        {
          path: 'result',
          component: InspectionResult,
          name: 'InspectionResult',
        },
        {
          path: 'resume',
          component: InspectionResume,
          name: 'InspectionResume',
        },
        {
          path: 'end',
          component: InspectionEnd,
          name: 'InspectionEnd',
        },
        {
          path: 'restart',
          component: InspectionRestart,
          name: 'InspectionRestart',
        },
        {
          path: 'temps',
          component: InspectionTemp,
          name: 'InspectionTemp',
        },
        {
          path: 'temps/:id([0-9]+)',
          component: InspectionTempDetail,
          name: 'InspectionTempDetail',
          props: (route) => ({ id: Number(route.params.id) }),
        },
      ],
    },
  ],
}
