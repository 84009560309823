<template>
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <div class="container">
      <span class="navbar-brand"
        ><slot name="navbarTitle">{{ title }}</slot>
      </span>
      <span class="navbar-text d-none d-sm-block me-auto"
        ><slot name="navbarSubTitle"
          ><i class="bi bi-person-square me-1"></i>{{ display_name }}</slot
        ></span
      >
      <slot name="navbarButton"></slot>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'InspectionNavbar',
  components: {},

  data() {
    return {
      title: '製品検査',
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/userData',
    }),
    display_name: function () {
      return this.userData('display_name')
    },
  },
}
</script>

<style scoped>
.nav-item {
  margin-left: 0.5rem;
}
</style>
