<template>
  <div v-if="showInspectionCamera">
    <Teleport to="body">
      <InspectionImageCamera
        @hidden-camera="stopImageCamera"
        @capture-camera="setImageCapture"
      />
    </Teleport>
  </div>
  <div v-else class="row row-cols-1 gy-3">
    <div class="col">
      <div class="position-relative">
        <ImageViewer
          ref="imageViewerElem"
          :file="{
            file_path: formData.photo,
            file_type: formData.file_type,
          }"
          :show_lightbox="false"
        />
        <div v-if="formData.photo" class="position-absolute top-0 end-0 m-2">
          <button
            type="button"
            class="btn btn-danger btn-sm ms-1"
            @click.prevent="deleteFile"
          >
            <i class="bi bi-trash3"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row g-2">
        <div class="col-md-6">
          <button
            class="btn btn-dark w-100 p-3 me-2"
            @click.prevent="startImageCamera"
          >
            <i class="bi bi-camera me-1"></i>カメラで撮影
          </button>
        </div>
        <div class="col-md-6">
          <FileSelect
            ref="fileSelect"
            @selected-func="fileSelected"
            :accept="accept"
          >
            <button
              type="button"
              class="btn btn-light w-100 p-3 me-2"
              @click.prevent="fileSelect"
            >
              <i class="bi bi-folder2 me-1"></i>ファイルを選択
            </button>
          </FileSelect>
        </div>
      </div>
    </div>
    <div class="col">
      <button
        class="btn btn-lg btn-success w-100 py-4"
        v-on:click.prevent="success()"
      >
        <i class="bi bi-check-circle-fill me-2"></i> 撮影しました
      </button>
    </div>
  </div>
</template>

<script>
import ImageViewer from '@/components/AppImageViewer.vue'
import FileSelect from '@/components/AppFileSelect.vue'
import InspectionImageCamera from '@/components/inspection/InspectionImageCamera.vue'

export default {
  name: 'InspectionCamera',
  components: {
    ImageViewer,
    FileSelect,
    InspectionImageCamera,
  },

  emits: ['resultFunc'],

  data() {
    return {
      showInspectionCamera: false,
      accept: 'image/jpeg,image/png',
      formData: {
        is_success: true,
        description: null,
        photo: '',
      },
    }
  },

  methods: {
    success() {
      if (!this.formData.photo) {
        this.$store.dispatch('alert/setErrorMessage', {
          message: '撮影してください。',
        })
      } else {
        this.$emit('resultFunc', this.formData)
      }
    },
    fileSelect() {
      this.$refs.fileSelect.fileEvent()
    },
    fileSelected(file_path, file_type) {
      this.formData.photo = file_path
      this.formData.file_type = file_type
    },
    deleteFile() {
      this.formData.photo = ''
      this.formData.file_type = ''
    },
    setImageCapture(file_path, file_type) {
      this.formData.photo = file_path
      this.formData.file_type = file_type
      this.stopImageCamera()
    },
    startImageCamera() {
      this.showInspectionCamera = true
    },
    stopImageCamera() {
      this.showInspectionCamera = false
    },
  },
}
</script>
