<template>
  <div class="row row-cols-1 gy-3">
    <div class="col">
      <div v-if="inspection_item.success_options.length > 0">
        <button
          v-for="option in inspection_item.success_options"
          :key="option"
          class="btn btn-lg btn-success w-100 py-3 mb-2"
          v-on:click.prevent="success(option)"
        >
          {{ option }}
        </button>
      </div>
      <button
        v-else
        class="btn btn-lg btn-success w-100 py-4"
        v-on:click.prevent="success(null)"
      >
        <i class="bi bi-check-circle-fill me-2"></i> 確認しました
      </button>
    </div>
    <div class="col">
      <div v-if="inspection_item.failure_options.length > 0">
        <button
          v-for="option in inspection_item.failure_options"
          :key="option"
          class="btn btn-lg btn-danger w-100 py-3 mb-2"
          v-on:click.prevent="failure(option)"
        >
          {{ option }}
        </button>
      </div>
      <button
        v-else
        class="btn btn-lg btn-danger w-100 py-4"
        v-on:click.prevent="failure(null)"
      >
        <i class="bi bi-x-circle-fill me-2"></i> NGでした
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InspectionSelect',

  emits: ['resultFunc'],
  props: ['inspection_item'],

  methods: {
    success(description) {
      this.$emit('resultFunc', {
        is_success: true,
        description: description,
        photo: null,
      })
    },
    failure(description) {
      this.$emit('resultFunc', {
        is_success: false,
        description: description,
        photo: null,
      })
    },
  },
}
</script>
