<template>
  <div class="ratio ratio-16x9 mb-3">
    <video :id="videoInputId" controls playsinline muted autoplay></video>
    <canvas
      :id="videoCanvasId"
      width="1280"
      height="720"
      class="bg-dark"
    ></canvas>
  </div>
</template>

<script>
import { randomString } from '@/utils/stringUtils.js'

export default {
  name: 'AppImageCamera',

  emits: ['afterCaptureFrame'],

  data() {
    return {
      isStream: false,
      isCapture: false,
      video: null,
      videoCanvas: null,
      videoContext: null,
    }
  },

  computed: {
    videoInputId: function () {
      return 'video-input-' + this.randomString()
    },
    videoCanvasId: function () {
      return 'video-canvas-' + this.randomString()
    },
  },

  mounted() {
    this.resetStream()
    this.videoCanvas = document.getElementById(this.videoCanvasId)
    this.videoContext = this.videoCanvas.getContext('2d')
  },
  unmounted() {
    this.resetStream()
  },

  methods: {
    randomString,
    resetStream() {
      this.isStream = false
      this.isCapture = false
      if (this.videoContext != null) {
        this.videoContext.clearRect(
          0,
          0,
          this.videoCanvas.width,
          this.videoCanvas.height
        )
      }
      this.clearStream()
    },
    startStream() {
      this.video = document.getElementById(this.videoInputId)
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            aspectRatio: 1.777777,
            facingMode: 'environment',
          },
        })
        .then((stream) => {
          this.video.srcObject = stream
          this.video.addEventListener('timeupdate', this.drawStream, false)
          this.isStream = true
        })
    },
    drawStream() {
      const currentSize = this.getCurrentSize(this.videoCanvas)
      this.videoContext.drawImage(
        this.video,
        0,
        0,
        this.video.videoWidth,
        this.video.videoHeight,
        currentSize.x,
        currentSize.y,
        currentSize.w,
        currentSize.h
      )
    },
    clearStream() {
      if (this.video != null) {
        this.video.srcObject.getVideoTracks().forEach((track) => {
          track.stop()
          this.video.srcObject.removeTrack(track)
        })
      }
      this.isStream = false
    },
    captureFrame() {
      this.isCapture = true
      this.videoContext.drawImage(this.videoCanvas, 0, 0)
      this.$emit(
        'afterCaptureFrame',
        this.videoCanvas.toDataURL('image/webp'),
        'image/webp'
      )
      this.clearStream()
    },
    getCurrentSize(targetCanvas) {
      let currentW = targetCanvas.width,
        currentH = targetCanvas.height,
        currentX = 0,
        currentY = 0

      if (this.video.videoWidth < this.video.videoHeight) {
        // 縦向き・幅に合わせる
        const videoR = targetCanvas.width / this.video.videoWidth
        currentW = targetCanvas.width
        currentH = this.video.videoHeight * videoR
      }
      if (this.video.videoWidth > this.video.videoHeight) {
        // 横向き
        const orgR = 9 / 16
        const videoR = this.video.videoHeight / this.video.videoWidth
        if (orgR < videoR) {
          // 比率が大きい場合は高さに合わせる
          const differenceW = targetCanvas.width - targetCanvas.width * videoR
          currentW = targetCanvas.width * videoR
          currentH = targetCanvas.height
          currentX = differenceW / 2
        }
        if (orgR > videoR) {
          // 比率が小さい場合は幅に合わせる
          const differenceH = targetCanvas.height - targetCanvas.height * videoR
          currentW = targetCanvas.width
          currentH = targetCanvas.height * videoR
          currentY = differenceH / 2
        }
      }

      return {
        w: currentW,
        h: currentH,
        x: currentX,
        y: currentY,
      }
    },
  },
}
</script>
