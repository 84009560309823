<template>
  <Navbar>
    <template v-slot:navbarButton>
      <router-link
        :to="{
          name: 'InspectionTemp',
        }"
        class="btn btn-dark"
        >戻る</router-link
      >
    </template>
  </Navbar>

  <main id="main" class="bg-light">
    <div class="container pt-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">検査データ</h5>
          <h6 class="card-subtitle mb-2 text-muted"></h6>
          <hr />
          <div v-if="inspection_temp.result">
            <table class="table table-bordered mb-3">
              <tbody>
                <tr>
                  <th class="col-sm-4 bg-light">検査番号</th>
                  <td class="col-sm-8">
                    {{ inspection_temp.result.inspection_result_number }}
                  </td>
                </tr>
                <tr>
                  <th class="bg-light">検査種別</th>
                  <td>{{ inspection_temp.result.inspection_type_name }}</td>
                </tr>
                <tr>
                  <th class="bg-light">検査シート名</th>
                  <td>{{ inspection_temp.result.inspection_name }}</td>
                </tr>
                <tr>
                  <th class="bg-light">検査開始日時</th>
                  <td>{{ inspection_temp.result.begin_datetime }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row row-cols-1 row-cols-md-2 gy-4">
            <!-- details -->
            <div
              v-if="
                inspection_temp.result &&
                inspection_temp.result.details &&
                inspection_temp.result.details.length > 0
              "
              class="col"
            >
              <h6>保存済み検査項目</h6>
              <ul class="list-group">
                <li
                  v-for="(detail, index) in inspection_temp.result.details"
                  :key="index"
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  {{ detail.inspection_item_name }}
                  <i
                    v-if="detail.is_success"
                    class="bi bi-check-circle-fill text-success"
                  ></i>
                  <i v-else class="bi bi-x-circle-fill text-danger"></i>
                </li>
              </ul>
            </div>
            <p v-else>検査が終了した検査項目がありません。</p>
            <!-- items -->
            <div
              v-if="
                inspection_temp.inspection &&
                inspection_temp.inspection.items &&
                inspection_temp.inspection.items.length > 0
              "
              class="col"
            >
              <h6>すべての検査項目</h6>
              <ul class="list-group">
                <li
                  v-for="(item, index) in inspection_temp.inspection.items"
                  :key="index"
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  {{ item.title }}
                  <span
                    @click="selectedInspection(item.position)"
                    class="text-primary"
                    >開始</span
                  >
                </li>
              </ul>
            </div>
            <p v-else>指定の検査シートには現在検査項目がありません。</p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Navbar from '@/components/inspection/InspectionNavbar.vue'

export default {
  name: 'InspectionTempDetail',
  components: {
    Navbar,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      inspection_temp: 'inspection_temp/detail',
    }),
  },
  async created() {
    await this.getData(this.id)
  },

  watch: {
    // 同ページ遷移のため変更を監視
    $route(to, from) {
      if (to.params.id) {
        this.getData(to.params.id)
      }
    },
  },
  methods: {
    ...mapMutations({
      setInspection: 'inspection/setDetail',
      setInspectionResult: 'inspection_result/setDetail',
      setItemPosition: 'inspection_result/setItemPosition',
    }),
    ...mapActions({
      getData: 'inspection_temp/fetchData',
    }),
    async selectedInspection(position) {
      await this.setInspection({
        inspection_id: this.inspection_temp.inspection.id,
        inspection_name: this.inspection_temp.inspection.name,
      })
      delete this.inspection_temp.result['details']
      await this.setInspectionResult(this.inspection_temp.result)
      await this.setItemPosition(position)
      this.$router.push({
        name: 'InspectionResult',
      })
    },
  },
}
</script>
